import React from "react"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import "./Home.scss"
import "./Leadership.scss"

const Leadership = () => {
    const navigate = useNavigate()
    return (
        <div className="leadership">
            <div className="text-width">
                <h2 className="title-page font-900 mb-24 mt-20">
                    <span className="d-block">
                        Diversifying Corporate Leadership
                    </span>
                    <span>By Leveling the MBA Playing Field.</span>
                </h2>
                <p className="p-gray-lato mb-24">
                    The Supreme Court’s June 2023 ruling against affirmative
                    action spurred an overdue public reckoning with the many
                    inequitable admissions practices of our higher education
                    system. Legacy preferences – which double or triple the odds
                     that the children of wealthy alumni and donors will be
                     admitted to selective schools – are now being challenged in
                      the courts.
                </p>
                <p className="p-gray-lato">
                    But legacy preferences are not the only advantage that
                    wealthy students enjoy.{" "}
                    <span className="p-strong-lato mb-30">
                        Few if any candidates get accepted into top graduate
                        programs without the use of standardized test
                        preparation and admissions consultants that are priced
                        far beyond the reach of most Americans.
                    </span>{" "}
                    When the admissions bar is set by students using private
                    coaches and professional writers, those who can’t pay are
                    weeded out, and the institutions that produce our future
                    business and political leaders become filled with the
                    already-wealthy: most of{" "}
                    <span className="p-strong-lato">
                        America’s top universities draw more students from
                        families at the top 1% of the income scale than from the
                        bottom 60%.
                    </span>{" "}
                    The lack of socio-economic diversity in our nation’s leading
                    MBA and graduate programs carries over to the C-Suite and
                    politics, reducing economic mobility and diminishing public
                    trust in our institutions.
                </p>
                <div className="btn-block btn-gre">
                    <Button
                        className="link-btn btn-green font-900"
                        onClick={() => navigate("/discussion-forum")}
                    >
                        ASK A QUESTION
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Leadership
