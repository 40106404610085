import React from "react"
import * as yup from "yup"
import { useFormik } from "formik"
import { Input, Button } from "antd"
import { useAddToMailingListMutation } from "store/reducers/authApi"
import { toast } from "react-toastify"
import reCAPTCHA from "utils/reCaptcha"
import "./AddToMailingList.scss"

let recaptcha = new reCAPTCHA(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY as string,
    "contact"
)
const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
})
const initialValues: { email: string } = {
    email: "",
}
const AddToMailingList = () => {
    const [addToMailingList, { isLoading }] = useAddToMailingListMutation()
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async () => {
            let token: string = await recaptcha.getToken()

            addToMailingList({
                ...formik.values,
                token: token,
                action: "contact",
            })
                .unwrap()
                .then(() => {
                    toast.success("Successfully Sent.", {
                        autoClose: 2000,
                        pauseOnHover: false,
                    })
                })
                .catch(() => {
                    toast.error("ReCaptcha Failed", {
                        autoClose: 2000,
                        pauseOnHover: false,
                    })
                })
                .finally(() => {
                    formik.resetForm()
                })
        },
    })
    return (
        <>
            <div className="add-to-mail">
                <h2 className="title-page font-900 mb-24 mt-20">
                    <span className="d-block">
                        Join our mailing list for information
                    </span>
                    <span>on upcoming workshops and events.</span>
                </h2>
                <div className="form">
                    <div className="text-center">
                        <label className="form-title">Your email address</label>
                    </div>

                    <Input
                        type="email"
                        placeholder="Email"
                        className="form-input"
                        value={formik.values.email}
                        name="email"
                        onChange={formik.handleChange}
                    />
                    <Button
                        className="btn-submit font-900"
                        disabled={
                            !(
                                formik.values.email &&
                                formik.isValid &&
                                !isLoading
                            )
                        }
                        onClick={() => formik.handleSubmit()}
                    >
                        <span>Confirm</span>
                    </Button>
                </div>
            </div>
            <p className="text-width p-gray-lato mt-30">
                For the student who can’t lean on familial networks for
                internships, professional opportunities, and letters of
                reference, standardized tests can open doors.{" "}
                <span className="p-strong-lato">
                    A test score offers a way for students from lower-performing
                    schools to demonstrate academic aptitude to admissions
                    officers
                </span>{" "}
                who may be uncertain, or even skeptical, about the quality of a
                transcript. Standardized tests allow for comparison across
                schools and experiences – and can be particularly important to
                veterans, as a means of demonstrating aptitude to admissions
                officers and human resource professionals who struggle in
                translating military service to school curricula and to the
                private sector.
            </p>
        </>
    )
}

export default AddToMailingList
