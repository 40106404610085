import React from "react"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import WhoWeAre from "../../../assets/images/who-we-are.svg"
import Expertise from "../../../assets/images/expertise.svg"
import WhatMakeDifferent from "../../../assets/images/what-make-different.svg"
import "./Home.scss"
import "./SupportStudents.scss"

const SupportStudents = () => {
    const navigate = useNavigate()
    return (
        <div className="support-students">
            <div className="text-width">
                <h2 className="title-page font-900 mt-20 blue-h2 mb-30">
                    Smart with a Heart leverages Sherpa Prep’s 20 years
                    ofteaching expertise to deliver affordable support to
                    students.
                </h2>
                <hr className="mt-30" />
                <div className="text-block dashed-border">
                    <div>
                        <img src={WhoWeAre} alt="Who We Are" />
                    </div>
                    <div className="p-strong-lato">Who we are</div>
                    <p className="p-gray-lato mb-0">
                        Smart with a Heart is the first organization in America
                        to provide{" "}
                        <span className="p-strong-lato">
                            professional GRE preparation and admission
                            consulting services on a nonprofit basis.
                        </span>{" "}
                        We believe deeply in the dream of educational equity and
                        the life-changing pathways to prosperity that higher
                        education provides.
                    </p>
                </div>
                <div className="text-block dashed-border">
                    <div>
                        <img src={Expertise} alt="Expertise" />
                    </div>
                    <div className="p-strong-lato">Our Expertise</div>
                    <p className="p-gray-lato mb-0">
                        <span className="p-strong-lato">Sherpa Prep’s</span>{" "}
                        success over the past 17 years has positioned it as
                        perhaps the single{" "}
                        <span className="p-strong-lato">
                            highest rated rated test prep company.
                        </span>{" "}
                        Their curriculum and network of expert instructors will
                        provide our students with a high quality learning
                        experience propelling them to achieve the scores they
                        desire.
                    </p>
                </div>
                <div className="text-block">
                    <div>
                        <img src={WhatMakeDifferent} alt="WhatMakeDifferent" />
                    </div>
                    <div className="p-strong-lato">What Makes Us Different</div>
                    <p className="p-gray-lato mb-0">
                        Our offering of{" "}
                        <span className="p-strong-lato">
                            asynchronous online learning modules coupled with
                            1-on-1 live instruction for $249
                        </span>{" "}
                        is unique to the industry. Further, our longstanding
                        mission-driven emphasis of expanding access to test prep
                        for veterans and under-resourced candidates is a notable
                        differentiator in today’s test prep environment.
                    </p>
                </div>
            </div>
            <div className="mba-section-content full-width">
                <p className="p-gray-lato text-width mb-30">
                    Providing high-quality test prep and admissions consulting
                    to those who can’t afford it is an expensive proposition.
                    Live online test prep courses generally cost $1,000-$2,000,
                    while private tutoring and admissions counseling services
                    range in price from $50 to over $1,000 per hour. There are
                    asynchronous prep courses available at much lower cost, but
                    the educational content of such programs is generally poor,
                    resulting in subpar outcomes. More importantly, {" "}
                    <span className="p-strong-lato">
                        asynchronous programs lack any human interaction, and
                        without an interpersonal element, it simply isn’t
                        possible to provide the emotional support, guidance,
                        encouragement, and mentorship that are often critical
                        to good academic outcomes
                    </span>{" "}
                    – particularly when the candidate is a first-generation college
                     student, an under-resourced individual, or simply a person
                     who is unfamiliar with “elite” academic mores.
                </p>
            </div>
            <div className="text-center btn-position">
                <Button
                    className="link-btn btn-green-shaded font-900"
                    onClick={() => navigate("/how-our-course-works")}
                >
                    HOW OUR PROGRAM WORKS
                </Button>
            </div>
            <p className="p-gray-lato text-width block-height">
                The founders of{" "}
                <a href="https://sherpaprep.com/">Sherpa Prep,</a> Jay Friedman
                and Nafeez Amin, are attempting to confront this resource
                problem by creating America’s first not-for-profit test prep and
                admissions consulting company, Smart with a Heart.{" "}
                <span className="p-strong-lato">
                    Leveraging decades of experience in the test prep industry,
                    they created a program that blends high-quality asynchronous
                    teaching modules – delivered by instructors with over 20
                    years of daily test-prep teaching experience – with
                    unlimited access to free, small-group office hours and a
                    series of one-on-one mentoring sessions with experts.
                </span>{" "}
                This system delivers personalized support during key moments of
                the prep journey, and ensures that struggling students will have
                access to timely live interventions that can prevent them from
                going off-track. In short, <span className="p-strong-lato">
                Smart with a Heart delivers access to
                the kind of high-quality, individualized support that affluent
                students have enjoyed for decades – at a cost of only a couple
                hundred dollars to the student.</span>
            </p>
        </div>
    )
}

export default SupportStudents
