import React from "react"
import DegressProvide from "../../../assets/images/Degrees-Provide.svg"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import "./Home.scss"
import "./DegreesProvide.scss"

const DegreesProvide = () => {
    const navigate = useNavigate()
    return (
        <div className="degrees-provide">
            <div className="text-width">
                <h2 className="title-page font-900 mt-20 blue-h2">
                    High quality degrees provide significant economic mobility –
                    but access to institutions continues to present a
                    challenge.
                </h2>
                <h3 className="p-strong-lato mb-24 mt-20 text-center">
                    Nationwide: % of Cohort from Low-Income Families vs. % of
                    These Students Earning in Middle Class
                </h3>
            </div>
            <div className="degrees-provide-graph">
              <img src={DegressProvide} alt="Degress Provide" />
            </div>
            <p className="p-gray-lato p-small mt-30 text-width">
                Source: Opportunity Insights, Baseline Cross-Sectional Estimates
                of Child and Parent Income Distribution by College.
            </p>
            <p className="p-gray-lato p-small text-width mb-30">
                Footnotes: 1. The schools included are those with 500 or more students in
                the cohort. 2. Students born between 1980 and 1982.
            </p>
            <div className="text-center">
                <Button
                    className="link-btn btn-green font-900"
                    onClick={() => navigate("/discussion-forum")}
                >
                    Ask a question
                </Button>
            </div>
            <p className="p-gray-lato mt-30 text-width pb-20">
                Solving the wealth advantage in admissions isn’t easy. One
                not-unreasonable notion is to back the movement for test
                optionality. In practice, however, the decision not to submit a
                test score too often serves as a red flag to competitive
                programs. The ban on affirmative action is also likely to make
                admissions committees more, not less, dependent on quantifiable
                criteria, such as GPA and test scores, as a hedge against future
                lawsuits. And{" "}
                <span className="p-strong-lato">
                    even if standardized tests do become less widely used, it
                    wouldn’t solve the fundamental problem.
                </span>{" "}
                All other admissions criteria – from letters of recommendation,
                to eloquent personal essays, to extracurriculars, to success in
                AP courses – are just as strongly correlated with wealth and
                network advantages.
            </p>
            <div className="mba-section-content full-width">
                <div className="text-width">
                    <p className="p-gray-lato">
                        <span className="p-strong-lato">
                            If we truly want fairness in admissions decisions,
                            we need to ensure that all candidates have access to
                            high-quality test prep and admissions advice, with
                            targeted person-to-person interventions at key
                            points in the application journey.
                        </span>{" "}
                        Without access to these services, under-resourced
                        students – especially first-generation college students
                        who can’t rely on family guidance for advice about which
                        degrees are worth having – are more likely to make
                        sub-optimal enrollment decisions that produce diminished
                        returns on their educational investments. In the worst
                        (and all too common) cases, these choices result in
                        burdensome or insurmountable levels of student debt.
                    </p>
                </div>
            </div>
            <div className="btn-block btn-position">
                <Button
                    className="link-btn btn-brown font-900"
                    onClick={() =>
                        window.open("https://givebutter.com/Vo9aqn", "_blank")
                    }
                >
                    Donate
                </Button>
                <Button
                    className="link-btn btn-blue font-900"
                    onClick={() => navigate("/connect")}
                >
                    Connect
                </Button>
            </div>
            <p className="p-gray-lato text-width block-height">
                Elite institutions want to recruit a more economically and
                racially diverse student body – a qualifying test score is often
                all it takes to get a worthy student through the gate. And let’s
                be clear: quality test prep doesn’t only benefit students who
                are aiming for Harvard.{" "}
                <span className="p-strong-lato">
                    Even a mid-level GRE score can keep students out of
                    predatory for-profit schools
                </span>{" "}
                and give them access to worthwhile degrees. Every tiny step up
                the admissions ladder improves the return on educational
                investment and helps people avoid decisions that result in tens
                of thousands of dollars in student loan debt and professional
                lament.
            </p>
        </div>
    )
}

export default DegreesProvide
