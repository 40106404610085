import AddToMailingList from "./AddToMailingList"
import BannerPhoto from "../../../assets/images/Home-Girl-1.png"
import "./Home.scss"
import Leadership from "./Leadership"
import CostOfApplying from "./CostOfApplying"
import DegreesProvide from "./DegreesProvide"
import BlockContent from "./BlockContent"
import SupportStudents from "./SupportStudents"
import Testimonials from "./Testimonials"
import { Seo } from "components/HOC/SEO"

const Home = () => {
    return (
        <>
            <Seo
                description="Smart with a Heart is the first organization in America to provide professional graduate school test prep and admission consulting services on a nonprofit basis."
                keywords="smart with a heart, non-profit, admissions, gre, gmat, mba, universities"
                title="Welcome - Smart with a Heart"
            />

            <div className="Home">
                <div className="banner-container container-full">
                    <div className="banner">
                        <div className="banner-image">
                            <img src={BannerPhoto} alt="banner-logo" />
                        </div>
                    </div>
                </div>
                <div className="text-width">
                    <h1>
                        <div className="title-page font-900">
                            Welcome to{" "}
                            <span className="blue-text">
                                Smart with a Heart:
                            </span>{" "}
                        </div>
                        <div className="title-page font-900 mb-24">
                            America's First{" "}
                            <span className="green-text">Not-for-Profit</span>{" "}
                            Test Prep and Admissions Advice
                        </div>
                    </h1>
                    <p className="p-gray-lato mb-0">
                        Smart with a Heart is the first organization in America
                        to provide professional graduate school test prep and
                        admission consulting services on a nonprofit basis.{" "}
                        <span className="p-strong-lato">
                            We believe deeply in the dream of educational equity
                            and expanding access to higher education.
                        </span>{" "}
                        We offer affordable GRE test prep, free admissions
                        advice, and connection to a community of fellow
                        applicants.
                    </p>
                </div>
                <BlockContent />
                <Leadership />
                <CostOfApplying />
                <AddToMailingList />
                <DegreesProvide />
                <SupportStudents />
                <Testimonials />
            </div>
        </>
    )
}

export default Home
