import React from "react"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import Testimonial1 from "../../../assets/images/testimonial-img-1.png"
import Testimonial2 from "../../../assets/images/testimonial-img-2.png"
import "./Home.scss"
import "./Testimonials.scss"

const Testimonials = () => {
    const navigate = useNavigate()
    return (
        <div className="testimonials">
            <div className="text-width block-content">
                <h2 className="title-page font-900 mt-20 blue-h2 text-center">
                    Candidates who have used our test prep and admissions
                    resources have had incredibly positive experiences.
                </h2>
                <div className="testimonails-block">
                    <div className="testimonail-img">
                        <img src={Testimonial1} alt="" />
                    </div>
                    <div className="testimonail-text">
                        <div className="p-gray-lato">
                            <span className="p-strong-lato">
                                "Smart with a Heart®
                            </span>{" "}
                            by Sherpa Prep®{" "}
                            <span className="p-strong-lato">
                                exceeded all of my expectations.
                            </span>{" "}
                            Nafeez took the arduousness of the admissions
                            journey and simplified it into manageable pieces.
                            The{" "}
                            <span className="p-strong-lato">
                                support, patience, and kindness of the entire
                                team
                            </span>{" "}
                            give you the feeling of family and that you are
                            never alone. I couldn't have asked for a better
                            community of teachers, friends, and advisors to go
                            on this journey with. Thank you, Smart with a Heart®
                            by Sherpa Prep® for everything and more!"
                        </div>
                        <div className="user-name">
                            <span className="p-strong-lato">
                                {" "}
                                — Janet Onwukamuche
                            </span>
                        </div>
                    </div>
                </div>
                <div className="testimonails-block testimonila-two">
                    <div className="testimonail-img">
                        <img src={Testimonial2} alt="" />
                    </div>
                    <div className="testimonail-text">
                        <div className="p-gray-lato">
                            “I cannot say enough good things about the Smart
                            with Heart® by Sherpa Prep® team! Nafeez{" "}
                            <span className="p-strong-lato">
                                provides invaluable advice, support, and
                                perspective throughout the entire MBA
                                application and decision process.
                            </span>{" "}
                            Whether it’s answering quick questions over email or
                            getting on a call, the Smart with Heart team shows
                            that they really care about students and{" "}
                            <span className="p-strong-lato">
                                truly live out the "Smart with Heart®" mission.
                            </span>
                        </div>
                        <div className="user-name">
                            <span className="p-strong-lato">
                                {" "}
                                — Mary Buckingham
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mba-section-content full-width">
                <p className="p-gray-lato text-width mb-24">
                    Smart with a Heart’s program is not a cure for all
                    educational inequities.{" "}
                    <span className="p-strong-lato">
                        But it is a surefire way to help a great number of
                        smart, less-privileged Americans achieve much better
                        admissions outcomes
                    </span>{" "}
                    than they would otherwise, just by leveling one small corner
                    of the playing field. In the wake of the Supreme Court
                    ruling, we need more nonprofit and private-sector solutions
                    of this nature, and we need to bring them to scale, quickly.
                    Without more original thinking, access to education will
                    never be democratized, and higher education will only
                    continue to expand the divide between the haves and the have
                    nots.
                </p>
                <div className="btn-block">
                    <Button
                        className="link-btn btn-brown font-900"
                        onClick={() =>
                            window.open(
                                "https://givebutter.com/Vo9aqn",
                                "_blank"
                            )
                        }
                    >
                        Donate
                    </Button>
                    <Button
                        className="link-btn btn-blue font-900"
                        onClick={() => navigate("/connect")}
                    >
                        Connect
                    </Button>
                    <Button
                        className="link-btn btn-green font-900"
                        onClick={() => navigate("/gre")}
                    >
                        Enroll
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
