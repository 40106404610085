import React from "react"
import { Navigate } from "react-router-dom"
import { useAppSelector } from "../../store/hooks"
type Props = {
    children: React.ReactNode
}
const RequireUser = ({ children }: Props) => {
    const { user,hasUser } = useAppSelector((state) => state.user)
    // eslint-disable-next-line no-constant-condition
    return hasUser ? (
        <>{children}</>
    ) : (
        <Navigate to="/auth/login" />
    )
}

export default RequireUser
