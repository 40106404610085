import React from "react"
import "./AppLoader.scss"
import { Space, Spin } from "antd"
const AppLoader = () => {
    return (
        <div className="AppLoader">
            <Space size="middle">
                <Spin size="large" />
            </Space>
        </div>
    )
}

export default AppLoader
