import React, { useEffect, useState } from "react"
import { Layout, Spin, Space } from "antd"
import { Outlet, useNavigate } from "react-router-dom"
import TopBar from "components/layout/topBar/TopBar"
import SideBar from "components/layout/sideBar/SideBar"
import "./Layout.scss"
import { useAppSelector } from "store/hooks"
import { Helmet } from "react-helmet-async"

const DashBoardLayout = () => {
    const [collapsed, setCollapsed] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [viewDashBoard, setViewDashBoard] = useState(false)
    const toggle = () => {
        setCollapsed((prevState) => !prevState)
    }

    const role = useAppSelector((state) => state.user.user.role)
    useEffect(() => {
        if (role === "Student") {
            navigate("/student")
        } else {
            setViewDashBoard(true)
        }
    }, [role, setIsLoading])

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {viewDashBoard && (
                <Layout className="DashBoardLayout">
                    <SideBar
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />
                    <Layout
                        className="site-layout"
                        style={
                            !collapsed
                                ? { marginLeft: "240px" }
                                : { marginLeft: "80px" }
                        }
                    >
                        <TopBar toggle={toggle} />
                        {!isLoading ? (
                            <div className="main-content">
                                <Outlet />
                            </div>
                        ) : (
                            <Space
                                style={{
                                    height: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                size="middle"
                            >
                                <Spin size="large" />
                            </Space>
                        )}
                    </Layout>
                </Layout>
            )}
        </>
    )
}

export default DashBoardLayout
