import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./store/store"
import reportWebVitals from "./reportWebVitals"
import ScrollToTop from "utils/ScrollToTop"
import { HelmetProvider } from 'react-helmet-async';
const helmetContext = {};
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <HelmetProvider context={helmetContext}>
    <BrowserRouter>
        <Provider store={store}>
            <ScrollToTop />
            <App />
        </Provider>
    </BrowserRouter>
    </HelmetProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
