import { Button } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"
import "./Banner.scss"
const Banner = () => {
    const navigate = useNavigate()
    return (
        <div className="Banner">
            <p className="Banner__title">
                What Are You Waiting For? Sign Up Today!
            </p>
            <div className="btnSection">
                <Button className="btnBanner signUpToday" onClick={() => navigate("/register/enroll")}>Sign up Now</Button>
                <Button
                    className="btnBanner tryBtn"
                    onClick={() => navigate("/our-mission")}
                >
                    Our Mission
                </Button>
            </div>
        </div>
    )
}

export default Banner
