import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
    AdminOfficeHoursResponse,
    CheckInCreateArguments,
    BulkCheckinsCreateArguments,
    BulkOfficeHoursCreateArguments,
    CheckinEntity,
    CreateAnnotationArguments,
    createOfficeHourArguments,
    CreateStudentArgument,
    createCourseRegistrationArguments,
    EnrollmentArguments,
    EnrollmentResponse,
    GetAnnotationsResponse,
    GetBooksResponse,
    GetChaptersResponse,
    GetCheckInsResponse,
    GetInstructorOfficeHoursCheckInResponse,
    GetInstructorOfficeHourShowResponse,
    GetInstructorResponse,
    GetLevelSynonyms,
    GetPendingCheckinResponse,
    GetPortalOfficeHoursReponse,
    GetPractiseQuestionsResponse,
    GetPractiseSectionsResponse,
    GetPractiseTestsResponse,
    GetQuestionsResponse,
    GetReviewsResponse,
    GetSearchStudentResponse,
    GetSyllabusResponse,
    LoginArguments,
    LoginResponse,
    OfficeHourRegisterationsResponse,
    showAdminOfficeHourResponse,
    showAdminCourseRegistrationResponse,
    UpdateAnnotationArguments,
    UserEntity,
} from "store/apiTypes"

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/`,
        prepareHeaders: (headers) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = localStorage.getItem("token")
            if (token) {
                headers.set("ACCESS-TOKEN", `${token}`)
            }
            return headers
        },
    }),
    tagTypes: ["OFFICEHOUR", "CHECKINS", "STUDENTS", "ANNOTATIONS", "PORTAL_CHECKIN", "PORTAL_OFFICE_HOURS", "COURSE_REGISTRATIONS"],
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginArguments>({
            query: (queryArg) => ({
                url: "auth/login",
                method: "POST",
                body: queryArg,
            }),
        }),
        studentEnrollment: builder.mutation<
            EnrollmentResponse,
            EnrollmentArguments
        >({
            query: (queryArg) => ({
                url: "registrations",
                method: "POST",
                body: queryArg,
            }),
        }),
        applyDiscount: builder.mutation<
            EnrollmentResponse,
            {
                course_registration_id: number,
                promo_code: string
            }
        >({
            query: (queryArg) => ({
                url: `course_coupons/apply`,
                method: "POST",
                body: {
                    course_registration_id: queryArg.course_registration_id,
                    promo_code: queryArg.promo_code,
                },
            }),
        }),
        createCourseCoupon: builder.mutation<
            void,
            {
                requester_email: string,
                requester_name: string,
                slug: string,
                token: string,
                action: string,
            }
        >({
            query: (queryArg) => ({
                url: `course_coupons`,
                method: "POST",
                body: {
                    course_coupon: {
                        requester_email: queryArg.requester_email,
                        requester_name: queryArg.requester_name,
                        slug: queryArg.slug,
                    },
                },
                headers: {
                    "RECAPTCHA-TOKEN": queryArg.token,
                    "RECAPTCHA-ACTION": queryArg.action,
                },
            }),
        }),
        verifyPhone: builder.mutation<any, { code: string }>({
            query: (queryArg) => ({
                url: "auth/phone/verify",
                method: "POST",
                body: queryArg,
            }),
        }),
        VerifyEmailOtp: builder.mutation<any, { code: string }>({
            query: (queryArg) => ({
                url: "auth/email/verify",
                method: "POST",
                body: queryArg,
            }),
        }),
        verifyMe: builder.query<LoginResponse, void>({
            query: () => ({
                url: "auth/me",
                method: "GET",
            }),
        }),
        verifyEmail: builder.query<void, { token: string }>({
            query: ({ token }) => ({
                url: `auth/password/${token}`,
                method: "GET",
            }),
        }),
        setPassword: builder.mutation<
            void,
            { token: string; password: string }
        >({
            query: ({ token, password }) => ({
                url: `auth/password/${token}`,
                method: "PUT",
                body: { password: password },
            }),
        }),
        requestPassword: builder.mutation<void, { email: string }>({
            query: (queryArg) => ({
                url: `auth/password/reset`,
                method: "POST",
                body: queryArg,
            }),
        }),
        sendCode: builder.mutation<{ success: string }, void>({
            query: () => ({
                url: "auth/phone/send_code",
                method: "POST",
            }),
        }),
        sendEmailOtpCode: builder.mutation<{ success: string }, void>({
            query: () => ({
                url: "auth/email/send_code",
                method: "POST",
            }),
        }),
        setPhone: builder.mutation<any, { phone: string }>({
            query: (queryArg) => ({
                url: "auth/phone/set",
                method: "POST",
                body: queryArg,
            }),
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: "auth/logout",
                method: "DELETE",
            }),
            invalidatesTags: ["OFFICEHOUR", "CHECKINS", "STUDENTS", "ANNOTATIONS", "PORTAL_CHECKIN", "PORTAL_OFFICE_HOURS", "COURSE_REGISTRATIONS"]
        }),
        getBooks: builder.query<GetBooksResponse, void>({
            query: () => ({
                url: "portal/books",
                method: "GET",
            }),
        }),
        getChapters: builder.query<GetChaptersResponse, { book_id: number }>({
            query: (queryArg) => ({
                url: "portal/chapters",
                method: "GET",
                params: queryArg,
            }),
        }),
        getQuestions: builder.query<
            GetQuestionsResponse,
            { chapter_id: number }
        >({
            query: (queryArg) => ({
                url: "portal/questions",
                method: "GET",
                params: queryArg,
            }),
        }),
        getPractiseTests: builder.query<GetPractiseTestsResponse, void>({
            query: () => ({
                url: "portal/practice_tests",
                method: "GET",
            }),
        }),
        getPractiseSections: builder.query<
            GetPractiseSectionsResponse,
            { practice_test_id: number }
        >({
            query: (queryArg) => ({
                url: "portal//practice_sections",
                method: "GET",
                params: queryArg,
            }),
        }),
        getPractiseQuestions: builder.query<
            GetPractiseQuestionsResponse,
            { practice_section_id: number }
        >({
            query: (queryArg) => ({
                url: "portal/practice_questions",
                method: "GET",
                params: queryArg,
            }),
        }),
        getAdminOfficeHours: builder.query<
            AdminOfficeHoursResponse,
            {
                date?: string
                course_type?: string
                status?: string
                search_type: string
                page: number
                per_page: number
            }
        >({
            query: (queryArg) => ({
                url: "admin/office_hours",
                method: "GET",
                params: queryArg,
            }),
            providesTags: ["OFFICEHOUR"],
        }),
        showAdminOfficeHour: builder.query<
            showAdminOfficeHourResponse,
            { id: number }
        >({
            query: ({ id }) => ({
                url: `admin/office_hours/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [
                { type: "OFFICEHOUR", id: arg.id },
            ],
        }),
        deleteAdminOfficeHour: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `admin/office_hours/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["OFFICEHOUR"],
        }),
        createAdminOfficeHour: builder.mutation<
            void,
            createOfficeHourArguments
        >({
            query: (queryArg) => ({
                url: "admin/office_hours",
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["OFFICEHOUR"],
        }),
        CreateBulkAdminOfficeHours: builder.mutation<
            GetPortalOfficeHoursReponse,
            BulkOfficeHoursCreateArguments
        >({
            query: (queryArg) => ({
                url: "admin/office_hours/bulk_create",
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["OFFICEHOUR"],
        }),
        UpdateAdminOfficeHour: builder.mutation<
            showAdminOfficeHourResponse,
            { id: number; update: createOfficeHourArguments }
        >({
            query: (queryArg) => ({
                url: `admin/office_hours/${queryArg.id}`,
                method: "PUT",
                body: queryArg.update,
            }),
            invalidatesTags: (result) => [
                "OFFICEHOUR",
                { type: "OFFICEHOUR", id: result?.office_hour.id },
            ],
        }),
        getInstructors: builder.query<GetInstructorResponse, void>({
            query: () => ({
                url: "admin/instructors",
                method: "GET",
            }),
        }),
        getOfficeCategories: builder.query<{ categories: string[] }, void>({
            query: () => ({
                url: "misc/office_hours/categories",
                method: "GET",
            }),
        }),
        getOfficeStatuses: builder.query<{ categories: string[] }, void>({
            query: () => ({
                url: "misc/office_hours/statuses",
                method: "GET",
            }),
        }),
        getCourseTypes: builder.query<{ course_types: string[] }, void>({
            query: () => ({
                url: "misc/course_types",
                method: "GET",
            }),
        }),

        getOfficeHourRegisterations: builder.query<
            OfficeHourRegisterationsResponse,
            { id: number; page: number; per_page: number }
        >({
            query: ({ id, page, per_page }) => ({
                url: `admin/office_hours/${id}/office_hour_registrations`,
                method: "GET",
                params: { page: page, per_page: per_page },
            }),
        }),
        createScheduleCheckin: builder.mutation<
            { check_in: CheckinEntity },
            CheckInCreateArguments
        >({
            query: (queryArg) => ({
                url: "instructor/check_ins",
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["CHECKINS"],
        }),
        createBulkCheckins: builder.mutation<
            GetCheckInsResponse,
            BulkCheckinsCreateArguments
        >({
            query: (queryArg) => ({
                url: "instructor/check_ins/bulk_create",
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: ["CHECKINS"],
        }),
        deleteScheduleCheckin: builder.mutation<void, { id: number }>({
            query: (queryArg) => ({
                url: `instructor/check_ins/${queryArg.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CHECKINS"],
        }),
        getPendingScheduleCheckin: builder.query<
            GetPendingCheckinResponse,
            { page: number; per_page: number }
        >({
            query: (queryArg) => ({
                url: "instructor/check_ins/pending",
                method: "GET",
                params: queryArg,
            }),
            providesTags: ["CHECKINS"],
        }),
        getConfirmedScheduleCheckin: builder.query<
            GetPendingCheckinResponse,
            { page: number; per_page: number }
        >({
            query: (queryArg) => ({
                url: "instructor/check_ins/confirmed",
                method: "GET",
                params: queryArg,
            }),
            providesTags: ["CHECKINS"],
        }),
        getInstructorOfficeHours: builder.query<
            GetInstructorOfficeHoursCheckInResponse,
            { page: number; per_page: number }
        >({
            query: (queryArg) => ({
                url: "instructor/office_hours",
                method: "GET",
                params: queryArg,
            }),
        }),
        showInstructorOfficeHourCheckin: builder.query<
            GetInstructorOfficeHourShowResponse,
            { id: string }
        >({
            query: (queryArg) => ({
                url: `instructor/office_hours/${queryArg.id}`,
                method: "GET",
            }),
        }),
        getSearchStudent: builder.query<
            GetSearchStudentResponse,
            { email: string }
        >({
            query: (queryArg) => ({
                url: `admin/students/search`,
                method: "GET",
                params: queryArg,
            }),
        }),
        getStudentById: builder.query<{ student: UserEntity }, { id: string }>({
            query: (queryArg) => ({
                url: `admin/students/${queryArg.id}`,
                method: "GET",
            }),
            providesTags: (result, err, arg) => [
                { type: "STUDENTS", id: arg.id },
            ],
        }),
        createStudent: builder.mutation<
            { student: UserEntity },
            CreateStudentArgument
        >({
            query: (queryArg) => ({
                url: `admin/students/`,
                method: "POST",
                body: queryArg,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "STUDENTS", id: result?.student?.id },
            ],
        }),
        showAdminCourseRegistration: builder.query<
            showAdminCourseRegistrationResponse,
            { student_id: number, id: number }
        >({
            query: ({ student_id, id}) => ({
                url: `admin/students/${student_id}/course_registrations/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [
                { type: "STUDENTS", id: arg.student_id },
                { type: "COURSE_REGISTRATIONS", id: arg.id },
            ],
        }),
        UpdateAdminCourseRegistration: builder.mutation<
            showAdminCourseRegistrationResponse,
            { student_id: number, id: number, update: createCourseRegistrationArguments }
        >({
            query: ({ student_id, id, update}) => ({
                url: `admin/students/${student_id}/course_registrations/${id}`,
                method: "PUT",
                body: update,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "STUDENTS", id: arg.student_id },
                { type: "COURSE_REGISTRATIONS", id: arg.id },
            ],
        }),
        getCourseRegisterationModes: builder.query<{ modes: string[] }, void>({
            query: (queryArg) => ({
                url: `misc/course_registration/modes`,
                method: "GET",
            }),
        }),
        getAdmissionVideos: builder.query<{ admission_videos: { id: number; name: string; video_url: string }[] }, void>({
            query: () => ({
                url: `portal/admission_videos`,
                method: "GET",
            }),
        }),
        getOfficeHorVideos: builder.query<{ recordings: { name: string; url: string; }[] }, void>({
            query: () => ({
                url: `portal/office_hours/recordings`,
                method: "GET",
            }),
        }),
        createAnnotation: builder.mutation<void, CreateAnnotationArguments>({
            query: (queryArg) => ({
                url: `portal/books/${queryArg.book_id}/pdf_notes`,
                method: "POST",
                body: { pdf_note: queryArg.pdf_note },
            }),
            invalidatesTags: ["ANNOTATIONS"],
        }),
        updateAnnotation: builder.mutation<void, UpdateAnnotationArguments>({
            query: (queryArg) => ({
                url: `portal/books/${queryArg.book_id}/pdf_notes/${queryArg.annotation_id}`,
                method: "PUT",
                body: { pdf_note: queryArg.pdf_note },
            }),
            invalidatesTags: ["ANNOTATIONS"],
        }),
        getAnnotations: builder.query<
            GetAnnotationsResponse,
            { book_id: string }
        >({
            query: (queryArg) => ({
                url: `portal/books/${queryArg.book_id}/pdf_notes`,
                method: "GET",
            }),
            providesTags: ["ANNOTATIONS"],
        }),
        deleteAnnotation: builder.mutation<
            GetAnnotationsResponse,
            { book_id: string; annotation_id: string }
        >({
            query: (queryArg) => ({
                url: `portal/books/${queryArg.book_id}/pdf_notes/${queryArg.annotation_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ANNOTATIONS"],
        }),
        postHelpRequest: builder.mutation<
            void,
            {
                name: string
                email: string
                message: string
                token: string
                action: string
            }
        >({
            query: (queryArg) => ({
                url: `help_request`,
                method: "POST",
                body: {
                    help_request: {
                        name: queryArg.name,
                        email: queryArg.email,
                        message: queryArg.message,
                    },
                },
                headers: {
                    "RECAPTCHA-TOKEN": queryArg.token,
                    "RECAPTCHA-ACTION": queryArg.action,
                },
            }),
        }),
        AddToMailingList: builder.mutation<
            void,
            {
                email: string
                token: string
                action: string
            }
        >({
            query: (queryArg) => ({
                url: `help_request/add_to_mailing_list`,
                method: "POST",
                body: {
                    email: queryArg.email,
                },
                headers: {
                    "RECAPTCHA-TOKEN": queryArg.token,
                    "RECAPTCHA-ACTION": queryArg.action,
                },
            }),
        }),
        homePageReviews: builder.query<GetReviewsResponse, void>({
            query: () => ({
                url: "reviews/homepage",
                method: "GET",
            }),
        }),
        GrePrepReviews: builder.query<GetReviewsResponse, void>({
            query: () => ({
                url: "reviews/gre_prep",
                method: "GET",
            }),
        }),
        payment: builder.mutation<
            { client_secret: string },
            { payment: { course_registration_id: number, user_id: number } }
        >({
            query: (queryArg) => ({
                url: `payments`,
                method: "POST",
                body: queryArg
            }),
        }),
        getCheckIns: builder.query<GetCheckInsResponse, { date: string }>({
            query: ({ date }) => ({
                url: `portal/check_ins?date=${date}`,
                method: "GET",
            }),
            keepUnusedDataFor: 5,
            providesTags: ["PORTAL_CHECKIN"]
        }),
        registerCheckIn: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `portal/check_ins/${id}/register`,
                method: "POST",
            }),
            invalidatesTags: ["PORTAL_CHECKIN"],
        }),
        getNextCheckIn: builder.query<{ check_in: { course_registration_id: number; course_type: string; date: string; id: number; time: string; zoom_link: string; zoom_meeting_id: string; meeting_at: string; } }, void>({
            query: () => ({
                url: `portal/check_ins/next`,
                method: "GET",
            }),
            providesTags: ["PORTAL_CHECKIN"]
        }),
        getCheckInVideos: builder.query<{ videos: { preparation: { name: string; url: string; }[] } }, void>({
            query: () => ({
                url: `portal/check_ins/videos`,
                method: "GET",
            }),
        }),
        cancelCheckIn: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `portal/check_ins/${id}/cancel`,
                method: "POST",
            }),
            invalidatesTags: ["PORTAL_CHECKIN"],
        }),
        getOfficeHourVideos: builder.query<{ videos: { questions: string[]; preparation: string[] } }, void>({
            query: () => ({
                url: `portal/office_hours/videos`,
                method: "GET",
            }),
        }),
        getNextOfficeHours: builder.query<{ office_hour: { category: string; course_type: string; date: string; id: number; start_time: string; end_time: string; zoom_link: string; zoom_meeting_id: string; google_sheet: string; start_at: string; end_at: string; } }, void>({
            query: () => ({
                url: `portal/office_hours/next`,
                method: "GET",
            }),
            providesTags: ["PORTAL_OFFICE_HOURS"]
        }),
        getPortalOfficeHours: builder.query<GetPortalOfficeHoursReponse, { date: string, category: string }>({
            query: ({ date, category }) => ({
                url: `portal/office_hours?date=${date}&category=${category}`,
                method: "GET",
            }),
            keepUnusedDataFor: 5,
            providesTags: ["PORTAL_OFFICE_HOURS"]
        }),
        registerOfficeHour: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `portal/office_hours/${id}/office_hour_registrations`,
                method: "POST",
            }),
            invalidatesTags: ["PORTAL_OFFICE_HOURS"],
        }),
        cancelOfficeHour: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `portal/office_hours/${id}/office_hour_registrations`,
                method: "DELETE",
            }),
            invalidatesTags: ["PORTAL_OFFICE_HOURS"],
        }),
        getHowToPrepareVideos: builder.query<{ preparation_guides: { id: number; name: string; video_url: string }[] }, void>({
            query: () => ({
                url: `portal/my_course/how_to_prepare`,
                method: "GET",
            }),
        }),
        getSmartStudyVideos: builder.query<{ learning_psychologies: { id: number; name: string; video_url: string }[] }, void>({
            query: () => ({
                url: `portal/my_course/learning_psychology`,
                method: "GET",
            }),
        }),
        getErrorLogVideos: builder.query<{ error_log_videos: { id: number; name: string; video_url: string }[] }, void>({
            query: () => ({
                url: `portal/error_log_videos`,
                method: "GET",
            }),
        }),
        getSupportVideos: builder.query<{ support_videos: { id: number; name: string; video_url: string }[] }, void>({
            query: () => ({
                url: `portal/my_course/support`,
                method: "GET",
            }),
        }),
        getTestTakingTipsVideos: builder.query<{ test_taking_advices: { id: number; name: string; video_url: string }[] }, void>({
            query: () => ({
                url: `portal/my_course/test_taking_advices`,
                method: "GET",
            }),
        }),
        getVocabularyVideos: builder.query<{ videos: string[] }, void>({
            query: () => ({
                url: `portal/my_course/vocabulary`,
                method: "GET",
            }),
        }),
        getlevelSynonyms: builder.query<GetLevelSynonyms, void>({
            query: () => ({
                url: `portal/level_synonyms`,
                method: "GET",
            }),
        }),
        getSyllabus: builder.query<GetSyllabusResponse, void>({
            query: () => ({
                url: `portal/my_course/syllabus`,
                method: "GET",
            }),
            keepUnusedDataFor: 5
        }),

    }),
})

export const {
    useLoginMutation,
    useVerifyPhoneMutation,
    useVerifyEmailOtpMutation,
    useSendCodeMutation,
    useSendEmailOtpCodeMutation,
    useSetPhoneMutation,
    useLogoutMutation,
    useGetBooksQuery,
    useGetChaptersQuery,
    useGetQuestionsQuery,
    useVerifyMeQuery,
    useGetAdminOfficeHoursQuery,
    useGetOfficeCategoriesQuery,
    useGetOfficeStatusesQuery,
    useGetCourseTypesQuery,
    useGetInstructorsQuery,
    useCreateAdminOfficeHourMutation,
    useCreateBulkAdminOfficeHoursMutation,
    useShowAdminOfficeHourQuery,
    useGetOfficeHourRegisterationsQuery,
    useUpdateAdminOfficeHourMutation,
    useDeleteAdminOfficeHourMutation,
    useCreateScheduleCheckinMutation,
    useCreateBulkCheckinsMutation,
    useGetPendingScheduleCheckinQuery,
    useDeleteScheduleCheckinMutation,
    useGetConfirmedScheduleCheckinQuery,
    useGetInstructorOfficeHoursQuery,
    useShowInstructorOfficeHourCheckinQuery,
    useGetSearchStudentQuery,
    useGetStudentByIdQuery,
    useGetCourseRegisterationModesQuery,
    useCreateStudentMutation,
    useShowAdminCourseRegistrationQuery,
    useUpdateAdminCourseRegistrationMutation,
    useGetAdmissionVideosQuery,
    useGetOfficeHorVideosQuery,
    useCreateAnnotationMutation,
    useGetAnnotationsQuery,
    useDeleteAnnotationMutation,
    useUpdateAnnotationMutation,
    usePostHelpRequestMutation,
    useAddToMailingListMutation,
    useHomePageReviewsQuery,
    useGrePrepReviewsQuery,
    useGetPractiseTestsQuery,
    useGetPractiseSectionsQuery,
    useGetPractiseQuestionsQuery,
    useStudentEnrollmentMutation,
    useApplyDiscountMutation,
    useCreateCourseCouponMutation,
    useVerifyEmailQuery,
    useSetPasswordMutation,
    useRequestPasswordMutation,
    usePaymentMutation,
    useGetCheckInsQuery,
    useRegisterCheckInMutation,
    useGetNextCheckInQuery,
    useGetCheckInVideosQuery,
    useCancelCheckInMutation,
    useGetOfficeHourVideosQuery,
    useGetNextOfficeHoursQuery,
    useGetPortalOfficeHoursQuery,
    useRegisterOfficeHourMutation,
    useCancelOfficeHourMutation,
    useGetHowToPrepareVideosQuery,
    useGetSmartStudyVideosQuery,
    useGetSupportVideosQuery,
    useGetTestTakingTipsVideosQuery,
    useGetVocabularyVideosQuery,
    useGetlevelSynonymsQuery,
    useGetSyllabusQuery,
    useGetErrorLogVideosQuery
} = authApi
