import React from "react"
import { Navigate } from "react-router-dom"
import { useAppSelector } from "store/hooks"
// import { useAppSelector } from '../../store/hooks';
type Props = {
    children: React.ReactNode
}
const ProtectedRouter = ({ children }: Props) => {
    const { isAuthenticated ,user} = useAppSelector((state) => state.user)
    // eslint-disable-next-line no-constant-condition
    return !isAuthenticated ? <>{children}</> : <Navigate to={user?.role === "Student" ? "/student" : "/portal"} />
}

export default ProtectedRouter
