import React from "react"
import { Navigate } from "react-router-dom"
import { useAppSelector } from "../../store/hooks"
type Props = {
    children: React.ReactNode
}
const RequireAuth = ({ children }: Props) => {
    const { isAuthenticated, user, hasUser } = useAppSelector((state) => state.user)
    const requireAuthHandler = () => {
        if (hasUser && user.email_verification_enabled && !user.email_otp_verified) {
            return <Navigate to="/auth/otp-verify" />
            // if (user.phone_verification_enabled && user.phone_number_verified) {
            //     return <Navigate to="/auth/phone-set" />
            // } else {
            //     return <Navigate to="/auth/phone-verify" />
            // }

        } else {
            return <Navigate to="/auth/login" />
        }
    }
    // eslint-disable-next-line no-constant-condition
    return isAuthenticated ? <>{children}</> : requireAuthHandler()
}

export default RequireAuth
