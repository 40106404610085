import React from "react"
import { Button } from "antd"
import "./OurMission.scss"
import Banner from "components/mainLayout/banner/Banner"
import BannerPhoto from "../../../assets/images/Banner-Logo.png"
import { Link, useNavigate } from "react-router-dom"
import { Seo } from "components/HOC/SEO"
const OurMission = () => {
    const navigate = useNavigate()
    return (
        <>
            <Seo description="Learn more about Smart with a Heart's Charitable Mission and Founders" keywords="nonprofit, mission, educational opportunity, military transition, URM, military veteran, women, under-resourced, first generation college students, standardized tests" title="Our Mission - Smart with a Heart" />
            <div className="OurMission">
                <div className="banner-container container-full">
                    <div className="banner">
                        <div className="banner-image">
                            <img src={BannerPhoto} alt="banner-logo" />
                        </div>
                        <div className="banner-text">
                            <h1 className="textMain">
                                <span className="text1">
                                    Increasing Access to Opportunity.
                                </span>
                                <span className="text2">
                                    Leveling the Playing Field.
                                </span>
                            </h1>
                            <div className="btn-Section">
                                <Button className="btn btnSignUp" onClick={() => navigate("/register/enroll")}>
                                    SIGN UP NOW
                                </Button>
                                <Button
                                    className="btn btnTrial"
                                    onClick={() => navigate("/")}
                                >
                                    HOME PAGE
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <h2 className="title-page mb-24">Our Mission</h2>
                    <p className="text-width p-gray-lato mb-24">
                        The deck is stacked against under-resourced students as they compete to enter top graduate programs. The process is time-consuming and expensive, and the entrance bar is set by affluent candidates using professional test preparation and admissions consulting services. The good news is that for lower-income individuals with the ability to obtain a competitive score, <span className="p-strong-lato">the GRE can serve as a powerful equalizer. </span>The problem is that <span className="p-strong-lato">the GRE tends to produce inequitable results because of how resource-intensive it is to prepare.</span>
                    </p>
                    <div className="mba-section-content full-width">
                        <p className="p-gray-lato text-width p-strong-lato">Our mission is to level the playing field by making premium, interpersonal test prep and admissions advice accessible to everyone.</p>
                    </div>
                    <p className="text-width p-gray-lato mb-24">
                        All too often, the lack of a qualifying GRE score is the only thing preventing a desirable candidate from making it past the initial cut and in front of an admissions committee.
                    </p>
                    <p className="text-width p-gray-lato mb-24">
                        But we don’t believe that eliminating standardized exams is the solution to educational inequality – because other entrance criteria are equally or more biased towards wealth. <span className="p-strong-lato">We believe in leveling the playing field by providing better and more affordable test prep resources, so we can widen the funnel of veteran, URM, first-generation and women applicants who can enter on their merits. </span> We believe the only thing they need is access to the same high-quality, personalized support that affluent candidates receive.
                    </p>
                    <h2 className="title-page mt-30 mb-24">Students Deserve Better</h2>
                    <p className="text-width p-gray-lato mb-24">
                        Smart with a Heart is a registered 501(c)(3) charitable organization. <span className="p-strong-lato">We increase access to educational opportunity through the most direct, effective method possible: by raising the standardized test scores of lower-income Americans who would otherwise be left behind.  </span>We offer intensive academic, moral, and emotional support to aspiring graduate students as they prep to take their standardized entrance exam, using <span className="p-strong-lato">a system of one-on-one expert mentoring and small-group live study hours available nowhere else in the test prep industry… at any price. </span> Our curriculum was developed by the founders of Sherpa Prep, a mission-driven test prep company with an alumni network of over 10,000 professionals and a 15-year track record of propelling under-resourced students into the nation’s top business and graduate schools.
                    </p>
                    <h2 className="title-page mt-30 mb-24">Our Founders</h2>
                    <p className="text-width p-gray-lato mb-24">
                        <span className="p-strong-lato">
                            Smart with a Heart is the first organization in America
                            that is attempting to provide premium test preparation
                            and admission consulting services on a nonprofit basis.
                        </span>{" "}
                        We want to do it because we believe deeply in the dream of
                        educational equity and the life-changing pathways to
                        prosperity that higher education provides. But we are able
                        to do it because our founders have spent 20 years in the
                        test prep industry, and through that experience have
                        developed a model that actually makes bespoke, affordable
                        test prep possible.
                    </p>
                    <p className="text-width p-gray-lato mb-40">
                        Smart with a Heart’s test prep universe and curriculum were
                        designed by{" "}
                        <span className="p-strong-lato">Sherpa Prep</span>, one
                        of the top-rated providers of GMAT and GRE preparation
                        services and graduate school admissions consulting in the
                        world. Sherpa Prep has unbroken 5-star ratings across the
                        Yelp, Google, and Trustpilot platforms, with a client base
                        drawn from across the United States. It is a social
                        enterprise whose mission, over the past 15 years, has been
                        to democratize test prep by offering premium, high-touch GRE
                        and GMAT training courses at a fraction of the cost of its
                        national competitors. Sherpa Prep’s high-quality programming
                        and dedication to educational equity has earned the support
                        of an impressive alumni network of more than 10,000
                        professionals, many hundreds of whom have gone on to
                        graduate with Top 10 MBAs and are now playing leadership
                        roles in name brand corporations across the country.{" "}
                        <span className="p-strong-lato">
                            Our board of directors is made up entirely of our
                            successful program alumni!
                        </span>
                    </p>
                    <div className="text-width btnSection mb-40">
                        <Button
                            className="btn btnMeet"
                            onClick={() => navigate("/meet-our-leaders")}
                        >
                            MEET OUR LEADERS
                        </Button>
                    </div>
                    <p className="text-width p-gray-lato mb-40">
                        Sherpa Prep’s owners, Jay Friedman and Nafeez Amin, founded
                        Smart with a Heart because they believe that educators have
                        an imperative to increase access to opportunity, ensuring
                        not only a more equitable society but a better one, too. To
                        accelerate that mission, they have created a{" "}
                        <Link to="/how-our-course-works"
                            style={{
                                color: "#0044CC",
                                textDecoration: "underline",
                            }}
                        >
                            unique, hybrid model of test preparation
                        </Link>{" "}
                        – one that lowers costs through asynchronous rather than
                        live instruction, but preserves the face-to-face advisory
                        meetings and small group office hours that have made Sherpa
                        Prep’s teaching so effective. The result is an affordable
                        but personalized test preparation product that truly has the
                        potential to revolutionize the admissions process by
                        diminishing the wealth advantage inherent in standardized
                        testing.
                    </p>
                    <div className="btnSection">
                        <Button className="btn btnSupport" onClick={() =>
                            window.open(
                                "https://givebutter.com/Vo9aqn",
                                "_blank"
                            )
                        }>SUPPORT US</Button>
                    </div>
                    <div className="container-full">
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurMission
