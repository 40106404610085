import React from 'react';

import { Helmet } from 'react-helmet-async';

export const Seo = ({ title, description, keywords }: { title: string, description: string, keywords: string }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="robots" content="index, follow" />
            <meta property="og:site_name" content="Smart with a Heart" />
            <meta name="twitter:site" content="Smart with a Heart" />
        </Helmet>
    );
};
