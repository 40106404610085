import React from "react"
import PropTypes from "prop-types"
import { Menu, Layout } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import {
    HomeOutlined,
    FileWordOutlined,
    HistoryOutlined,
    UserOutlined,
    ScheduleOutlined,
    CheckSquareOutlined,
} from "@ant-design/icons"
import "./sideBar.scss"
import { useAppSelector } from "store/hooks";
import AppLogo from "../../../assets/images/mainLogo.svg"
import PopUpLogo from "../../../assets/images/PopUpLogo.svg"
const { Sider } = Layout
const Routes = [
    {
        key: "/portal",
        label: "Videos",
        icon: <HomeOutlined />,
        role: "Student",
    },
    {
        key: "/portal/admin/office-hours",
        label: "Office Hours",
        icon: <FileWordOutlined />,
        role: "Admin",
    },
    {
        key: "/portal/admin/office-hours/archive",
        label: "Archived Office Hours",
        icon: <HistoryOutlined />,
        role: "Admin",
    },
    {
        key: "/portal/admin/students",
        label: "Students",
        icon: <UserOutlined />,
        role: "Admin",
    },
    {
        key: "/portal/instructor/unbooked-checkins",
        label: "Unbooked Check Ins",
        icon: <ScheduleOutlined />,
        role: "Instructor",
    },
    {
        key: "/portal/instructor/appointments",
        label: "Appointments",
        icon: <CheckSquareOutlined />,
        role: "Instructor",
    },
]
interface SidebarProps {
    collapsed: boolean
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}
const SideBar = ({ collapsed, setCollapsed }: SidebarProps) => {
    const location = useLocation()
    const navigate = useNavigate()
    const role = useAppSelector((state) => state.user.user.role)
    const routes = Routes.filter(
        (route) => route.role === role || route.role === "all"
    )
    return (
        <>
            {!collapsed ? (
                <div
                    className="sideBarOverlay"
                    onClick={() => setCollapsed((prevState) => !prevState)}
                />
            ) : (
                ""
            )}
            <Sider
                className="SideBar"
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={240}
                style={{
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    overflow: "hidden",
                }}
            >
                {!collapsed ? (
                    <div className="logo">
                        <img src={AppLogo} alt="Logo" />
                    </div>
                ) : (
                    <div className="logo small">
                        <img src={PopUpLogo} alt="Logo" />
                    </div>
                )}
                <Menu
                    mode="inline"
                    className="SidebarMenu"
                    defaultSelectedKeys={[`${location.pathname}`]}
                    items={routes}
                    onClick={(info) => navigate(info.key)}
                />
            </Sider>
        </>
    )
}

SideBar.propTypes = {
    collapsed: PropTypes.bool.isRequired,
}

export default SideBar
