import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "store/hooks"
interface RequirePermissionProps {
    children: React.ReactNode
    permission: string
}
const RequireRole = ({ children, permission }: RequirePermissionProps) => {
    const role = useAppSelector((state) => state.user.user.role)
    const navigate = useNavigate()
    const NavigateOnrole = () => {
        switch (role) {
            case "Instructor":
                return navigate("/portal/instructor/schedule-checkin")
            case "Admin":
                return navigate("/portal/admin/office-hours")
            case "Student":
                return navigate("/portal/student/videos")
        }
    }
    const verifiyPermission = () => {
        if (role === permission) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        if (!verifiyPermission()) {
            NavigateOnrole()
        }
    }, [])
    return <>{verifiyPermission() ? children : ""}</>
}

export default RequireRole
