import { createSlice } from "@reduxjs/toolkit"

interface appState {
    videoRouteTrigger: boolean
}

const initialState: appState = {
    videoRouteTrigger: false,
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        triggerVideoRoute(state) {
            state.videoRouteTrigger = !state.videoRouteTrigger
        },
    },
})

export const { triggerVideoRoute } = appSlice.actions
export default appSlice.reducer
