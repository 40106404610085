import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { UserEntity } from "store/apiTypes"

interface userState {
    isAuthenticated: boolean
    user: UserEntity
    hasUser : boolean;
}

const initialState: userState = {
    isAuthenticated: false,
    user: {} as UserEntity,
    hasUser : false
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUserSuccess(state, action: PayloadAction<UserEntity>) {
            localStorage.setItem("token", action.payload.access_token as string)
            state.user = action.payload
            state.hasUser = true;
        },
        getUser(state, action: PayloadAction<UserEntity>) {
            state.user = action.payload
            state.hasUser = true;
        },
        getVerifySuccess(state, action: PayloadAction<UserEntity>) {
            state.user = action.payload
            state.hasUser = true;
            state.isAuthenticated = true
        },
        getLoginSuccess(state) {
            state.isAuthenticated = true
        },
        getUserFail(state) {
            state.isAuthenticated = false
            localStorage.removeItem("token")
            state.user = {} as UserEntity
            state.hasUser = false;
        },
    },
})

export const {
    getUserSuccess,
    getUserFail,
    getLoginSuccess,
    getVerifySuccess,
    getUser,
} = userSlice.actions
export default userSlice.reducer
