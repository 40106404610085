import { configureStore } from "@reduxjs/toolkit"

import { authApi } from "./reducers/authApi"
import userReducer from "./reducers/userSlice"
import appReducer from "./reducers/appSlice"
export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        user: userReducer,
        app: appReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
