import React, { useState } from "react"
import { Layout, Avatar, Popover } from "antd"
import "./TopBar.scss"
import { useDispatch } from "react-redux"
import { MenuOutlined, UserOutlined } from "@ant-design/icons"
import { useAppSelector } from "store/hooks"
import { getUserFail } from "store/reducers/userSlice"
import { useLogoutMutation } from "store/reducers/authApi"
const { Header } = Layout
interface TopBarProps {
    toggle: () => void
}
const TopBar = ({ toggle }: TopBarProps) => {
    const { role, name } = useAppSelector((state) => state.user.user)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [logout] = useLogoutMutation()
    const logoutHandler = () => {
        logout()
            .unwrap()
            .then(() => {
                dispatch(getUserFail())
            })
    }

    return (
        <Header className="site-layout-background">
            <div className="toggleWrapper" onClick={toggle}>
                <MenuOutlined
                    className="trigger"
                    style={{ marginRight: "5px" }}
                />
            </div>
            <div className="USERLOGO">
                <div className="userInfo">
                    <p className="name">{name}</p>
                    <p className="role">{role}</p>
                </div>
                <Popover
                    content={<a onClick={logoutHandler}>Logout</a>}
                    trigger="click"
                    open={open}
                    onOpenChange={() => setOpen((prevState) => !prevState)}
                >
                    <Avatar
                        size={54}
                        icon={<UserOutlined color="#fff" />}
                        style={{
                            backgroundColor: "#Fec801",
                            border: "1px solid #fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    />
                </Popover>
            </div>
        </Header>
    )
}

export default TopBar
