import React from "react"
import CostGraph from "../../../assets/images/Cost-of-applying.svg"
import "./Home.scss"
import "./CostOfApplying.scss"

const CostOfApplying = () => {
    return (
        <div className="cost-applying">
            <div className="text-width">
                <h2 className="title-page font-900 mb-24 mt-20 blue-h2">
                    <span className="d-block">
                        Access to graduate programs is limited by the high
                    </span>
                    <span>cost of applying to top tier schools.</span>
                </h2>
                <div className="graph-block">
                    <div className="graph-img">
                        <img src={CostGraph} alt="Cost Graph" />
                    </div>
                    <div className="graph-text text-width">
                        <ul>
                            <li>
                                <span className="p-strong-lato">
                                    28.8% of respondents spent more than $5k
                                    applying
                                </span>{" "}
                                to MBA programs (typical costs include test
                                prep, admissions consulting, and application
                                fees)
                            </li>
                            <li>
                                Anecdotally, the higher ranked the program, the
                                more applicants spend to apply
                            </li>
                            <li>
                                For a typical 3-school package,{" "}
                                <span className="p-strong-lato">
                                    73.7% of admissions consultants charge more
                                    than $5k
                                </span>
                            </li>
                            <li>
                                For many veteran and under-resourced candidates,
                                these{" "}
                                <span className="p-strong-lato">
                                    costs create a significant barrier
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="text-width">
                <p className="p-gray-lato p-small mb-40">
                    Footnotes: 1. 2023 Reddit MBA Survey with 1,323 respondents.
                    2. Data collected by Poets and Quants in 2023. 3-school
                    packages generally offer items including application
                    strategy, personal brand and career development, resume
                    assistance, essay guidance, waitlist strategy, scholarship
                    negotiation, and letters of recommendation preparation.
                </p>
                <p className="p-gray-lato mb-24">
                    Solving the wealth advantage in admissions isn’t easy. One
                    not-unreasonable notion is to back the movement for test
                    optionality. In practice, however, the decision not to
                    submit a test score too often serves as a red flag to
                    competitive programs. The ban on affirmative action is also
                    likely to make admissions committees more, not less,
                    dependent on quantifiable criteria, such as GPA and test
                    scores, as a hedge against future lawsuits. And{" "}
                    <span className="p-strong-lato">
                        even if standardized tests do become less widely used,
                        it wouldn’t solve the fundamental problem.
                    </span>{" "}
                    All other admissions criteria – from letters of
                    recommendation, to eloquent personal essays, to
                    extracurriculars, to success in AP courses – are just as
                    strongly correlated with wealth and network advantages.
                </p>
            </div>
        </div>
    )
}

export default CostOfApplying
